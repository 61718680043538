<template>
  <div>
    <div>

      <div :key="Key" v-for="(tr, Key) in trucksData.VehiclesCategories">
        <div>
          <vx-card v-bind:title="$t(tr.Name)" class="mb-base">
            <h1>{{ tr.VehiclesCategoryName }}</h1>
              <vx-card class="vx-col m-2" v-for="(item, key) in tr.VehiclesShapes" :key="key">
                <div class="vx-row justify-center">
                  <div>
                    <img
                    width="100"
                    height="100"
                    class="Size"
                    alt="No Image"
                    :src="item.ImagURL"
                  />
                     <h4 style="text-align:center">{{item.ShapeName}}</h4>
                  </div>
                 
                </div>
                <vs-button class="vx-col justify-center" v-for="(shap, key) in item.VehiclesTypes" :key="key" type="flat" >
                  <img
                  style="width:100px;height:100px"
                    class="Size"
                    alt="No Image"
                    :src="shap.ImagURL"
                  />
                  <br />
                     <h4>{{shap.VehiclesTypeName}}</h4>
                    <vs-checkbox
                    class="vx-row justify-center" v-for="(vic, key) in shap.VehiclesAxles" :key="key"
                    :value="true"
                    :disabled="true"
                    >{{vic.VehiclesAxlesName}}</vs-checkbox
                  >
                </vs-button>
               
              </vx-card>
          </vx-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moduleDriver from "@/store/driver/moduleDriver.js";

export default {
  
  data() {
    return {
      popupActive1: false,
      selected: false,
      trucksData: [],
      image: "",
      VehicleOption: "",
      CanBeSeen: true,
      realImage: null,
      dumpTruck: null,
      IsDumpTruck: false,
      activeIndex: null
    };
  },
  computed: {
    validateForm() {
      return !this.errors.any() && this.TruckData.VehicleType !== "";
    }
  },
  methods: {
    Proceed() {
      this.popupActive1 = false;
      this.$emit("Proceed");
    },
    selectedcar(tr, index) {
      this.activeIndex = index;
      this.CanBeSeen = true;
      this.TruckData.VehicleType = tr.VehicleType;
      this.image = tr.image;
      this.IsDumpTruck = false;
      if (tr.VehicleOption) {
        this.TruckData.VehicleOption = tr.VehicleOption;
      } else {
        this.TruckData.VehicleOption = "";
      }
      this.realImage = tr.realImage;
      if (tr.dumpTruck) {
        this.dumpTruck = tr.dumpTruck;
      } else {
        this.dumpTruck = null;
      }
      this.selected = true;
    },
    Select(tr) {
      this.activeIndex = null;
      if (this.types.length > 0) {
        this.types = [];
        this.IsDumpTruck = false;
        this.CanBeSeen = false;
        this.TruckData.VehicleType = null;
        this.TruckData.VehicleOption = "";
        this.types = tr;
        this.popupActive1 = true;
      } else {
        this.IsDumpTruck = false;
        this.types = tr;
        this.CanBeSeen = true;
        this.popupActive1 = true;
      }
    }
  },
  created() {
    if (!moduleDriver.isRegistered) {
      this.$store.registerModule("DriverList", moduleDriver);
      moduleDriver.isRegistered = true;
    }  
    this.$vs.loading();
    this.$store
        .dispatch("DriverList/GetAllTruck").then(res=>{
          debugger
           this.$vs.loading.close();
          this.trucksData = res.data;
        }).catch(Error => {
          if (Error.status == 400) {
            this.$vs.notify({
              title: this.$t("Error"),
              text: Error.data.Error,
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "danger"
            });
          }
          this.$vs.loading.close();
        });
  }
};
</script>
<style lang="scss">
.Size {
  width: 300px !important;
  height: 90px !important;
}
.sidebar {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100px;
  h4 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    > button {
      margin-left: 10px;
    }
  }
}

.footer-sidebar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  > button {
    border: 0px solid rgba(0, 0, 0, 0) !important;
    border-left: 1px solid rgba(0, 0, 0, 0.07) !important;
    border-radius: 0px !important;
  }
}
#avatar-col {
  width: 10rem;
}
#page-Biadjo-view {
  table {
    td {
      vertical-align: baseline !important;
      min-width: 140px;
      padding-bottom: 0.8rem;
      word-break: break-all;
    }

    &:not(.permissions-table) {
      td {
        @media screen and (max-width: 370px) {
          display: block;
        }
      }
    }
  }
}

@media screen and (min-width: 1201px) and (max-width: 1211px),
  only screen and (min-width: 636px) and (max-width: 991px) {
  #account-info-col-1 {
    width: calc(100% - 12rem) !important;
  }
}
.login-tabs-container {
  min-height: 495px;

  .con-tab {
    padding-bottom: 14px;
  }

  .con-slot-tabs {
    margin-top: 1rem;
  }
}
</style>
